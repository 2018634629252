import * as React from "react";
import Layout from "../components/global/Layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="404">
      <section className="container mx-auto px-5 py-16">
        <h2 className="text-2xl text-center">Strony nie znaleziono</h2>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
